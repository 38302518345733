import { createAsyncThunk } from "@reduxjs/toolkit";
import { customTokenLogin } from "../services/firebase/firebase-client";
import store from "./onboardingStore";
import { auth } from "../services/firebase/fire";
import {
  authenticateEmail,
  digiLockerInit,
  digioPreESign,
  fetchBank,
  fetchBranch,
  fetchUserAccountStatus,
  generateOtp,
  resendEmailVerification,
  storeAddress,
  storeSegmentFatca,
  uploadDoubleSidedProof,
  uploadProof,
  verifyAndRegisterUser,
  verifyBank,
  verifyKra,
  verifyOtp,
  verifyPersonalDetails,
  getConsentDetails,
  getElgibility,
  setClientCodeInCRM,
} from "../services/api/api";
import { rejects } from "assert";

export const customTokenLoginThunk = createAsyncThunk(
  "onboarding/customTokenLogin",
  async (token) => {
    const res = await customTokenLogin(token).then((data: any) => data.user);
    return res;
  }
);

export const sendOtp = createAsyncThunk(
  "onboarding/sendOtp",
  async ({ mobile, email }: { mobile: string; email: string }, thunkApi) => {
    try {
      const accessToken = await auth?.currentUser?.getIdToken();
      const uid = auth?.currentUser?.uid;
      const res = await verifyAndRegisterUser(accessToken, uid, mobile, email);
      return res;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const verifyOtpThunk = createAsyncThunk(
  "onboarding/verifyOtpThunk",
  async (
    {
      mobile,
      email,
      otp,
    }: {
      mobile: string;
      email: string;
      otp: string;
    },
    thunkApi
  ) => {
    try {
      const accessToken = await auth?.currentUser?.getIdToken();
      const res = await verifyOtp(
        accessToken,
        store.getState().onboarding.clientCode,
        mobile,
        email,
        otp,
        store.getState().onboarding.mobileConsent,
        store.getState().onboarding.emailConsent
      );
      return res;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchBanksThunk = createAsyncThunk(
  "onboarding/fetchBanksThunk",
  async ({}, thunkApi) => {
    try {
      const accessToken = await auth?.currentUser?.getIdToken();
      const res = await fetchBank(
        accessToken,
        store.getState().onboarding.clientCode
      );
      return res;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchBranchThunk = createAsyncThunk(
  "onboarding/fetchBranchThunk",
  async ({ searchParam }: { searchParam: string }, thunkApi) => {
    try {
      const accessToken = await auth?.currentUser?.getIdToken();
      const res = await fetchBranch(
        accessToken,
        store.getState().onboarding.clientCode,
        store.getState().onboarding.bankName,
        searchParam
      );
      return res;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const verifyBankThunk = createAsyncThunk(
  "onboarding/verifyBankThunk",
  async (
    { ifscCode, accountNumber }: { ifscCode: string; accountNumber: string },
    thunkApi
  ) => {
    try {
      const accessToken = await auth?.currentUser?.getIdToken();
      const res = await verifyBank(
        accessToken,
        store.getState().onboarding.clientCode,
        store.getState().onboarding.bankName,
        ifscCode,
        accountNumber
      );
      return res;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const uploadProofThunk = createAsyncThunk(
  "onboarding/uploadProofThunk",
  async (
    { formData, type }: { formData: FormData; type: string },
    thunkApi
  ) => {
    try {
      const accessToken = await auth?.currentUser?.getIdToken();
      const res = await uploadProof(accessToken, formData);
      return res;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const uploadDoubleSidedProofThunk = createAsyncThunk(
  "onboarding/uploadDoubleSidedProofThunk",
  async ({ formData }: { formData: FormData }, thunkApi) => {
    try {
      const accessToken = await auth?.currentUser?.getIdToken();
      const res = await uploadDoubleSidedProof(accessToken, formData);
      return res;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const storeSegmentFatcaThunk = createAsyncThunk(
  "onboarding/storeSegmentFatcaThunk",
  async ({}, thunkApi) => {
    try {
      const accessToken = await auth?.currentUser?.getIdToken();
      const res = await storeSegmentFatca(
        accessToken,
        store.getState().onboarding.clientCode
      );
      return res;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const digiLockerInitThunk = createAsyncThunk(
  "onboarding/digiLockerInitThunk",
  async ({}, thunkApi) => {
    try {
      const accessToken = await auth?.currentUser?.getIdToken();
      const res = await digiLockerInit(
        accessToken,
        store.getState().onboarding.clientCode
      );
      return res;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const storeAddressThunk = createAsyncThunk(
  "onboarding/storeAddressThunk",
  async (data: Object, thunkApi) => {
    try {
      const accessToken = await auth?.currentUser?.getIdToken();
      const fileRes = await uploadDoubleSidedProof(
        accessToken,
        store.getState().onboarding.addressProofFiles
      );
      const res = await storeAddress(
        accessToken,
        store.getState().onboarding.clientCode,
        data
      );
      return { ...fileRes, ...res };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const verifyKraThunk = createAsyncThunk(
  "onboarding/verifyKraThunk",
  async (
    {
      pan,
      dob,
      name,
      fatherName,
    }: { pan: string; dob: string; name: string; fatherName: string },
    thunkApi
  ) => {
    try {
      const accessToken = await auth?.currentUser?.getIdToken();
      const res = await verifyKra(
        accessToken,
        store.getState().onboarding.clientCode,
        pan,
        dob,
        name,
        fatherName
      );
      return res;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const verifyPersonalDetailsThunk = createAsyncThunk(
  "onboarding/verifyPersonalDetailsThunk",
  async (data: Object, thunkApi) => {
    try {
      const accessToken = await auth?.currentUser?.getIdToken();
      const res = await verifyPersonalDetails(
        accessToken,
        store.getState().onboarding.clientCode,
        data
      );
      return res;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const generateOtpThunk = createAsyncThunk(
  "onboarding/generateOtpThunk",
  async ({}, thunkApi) => {
    try {
      const accessToken = await auth?.currentUser?.getIdToken();
      const res = await generateOtp(
        accessToken,
        store.getState().onboarding.clientCode,
        store.getState().onboarding.mobile,
        store.getState().onboarding.email
      );
      return res;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const digioPreESignThunk = createAsyncThunk(
  "onboarding/digioPreESignThunk",
  async ({}, thunkApi) => {
    try {
      const accessToken = await auth?.currentUser?.getIdToken();
      const res = await digioPreESign(
        accessToken,
        store.getState().onboarding.clientCode
      );
      return res;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const authenticateEmailThunk = createAsyncThunk(
  "onboarding/authenticateEmailThunk",
  async ({}, thunkApi) => {
    try {
      const accessToken = await auth?.currentUser?.getIdToken();
      const res = await authenticateEmail(
        accessToken,
        store.getState().onboarding.clientCode,
        store.getState().onboarding.mobile,
        store.getState().onboarding.email
      );
      return res;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const resendEmailVerificationThunk = createAsyncThunk(
  "onboarding/resendEmailVerificationThunk",
  async ({}, thunkApi) => {
    try {
      const accessToken = await auth?.currentUser?.getIdToken();
      const res = await resendEmailVerification(
        accessToken,
        store.getState().onboarding.clientCode,
        store.getState().onboarding.mobile,
        store.getState().onboarding.email
      );
      return res;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchUserAccountStatusThunk = createAsyncThunk(
  "onboarding/fetchUserAccountStatusThunk",
  async ({}, thunkApi) => {
    try {
      const accessToken = await auth?.currentUser?.getIdToken();
      const res = await fetchUserAccountStatus(
        accessToken,
        store.getState().onboarding.marketfeedDetails
      );
      return res;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getConsentDetailsThunk = createAsyncThunk(
  "onboarding/getConsentDetailsThunk",
  async ({}, thunkApi) => {
    try {
      const accessToken = await auth?.currentUser?.getIdToken();
      const res = await getConsentDetails(accessToken);
      return res;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getElgibilityThunk = createAsyncThunk(
  "onboarding/getElgibilityThunk",
  async (
    { clientCode, mobileNumber }: { clientCode: string; mobileNumber: string },
    thunkApi
  ) => {
    try {
      const upsertCRM = await setClientCodeInCRM({
        cf_client_id: clientCode,
        mobile_number: mobileNumber,
      });
      if (upsertCRM.status == "created") {
        const accessToken = await auth?.currentUser?.getIdToken();
        const res = await getElgibility(accessToken, clientCode);
        return res;
      }
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);
