import { axiosRequest, axiosRequestLMS } from "./axiosConfig";
import { MarketfeedDetails } from "@/redux/onboardingInitialStateTypes";

export const verifyAndRegisterUser = async (
  accessToken: string | undefined,
  uid: string | undefined,
  mobile: string,
  email: string
) => {
  try {
    const res = await axiosRequest({
      method: "post",
      path: "verify-and-register",
      data: {
        mobileNumber: mobile,
        email: email,
        uid: uid,
      },
      idToken: accessToken,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const verifyOtp = async (
  accessToken: string | undefined,
  clientCode: string | null,
  mobile: string,
  email: string,
  otp: string,
  mobileConsent: string,
  emailConsent: string
) => {
  try {
    const res = await axiosRequest({
      method: "post",
      path: "verify-otp",
      data: {
        mobileNumber: mobile,
        email: email,
        clientCode: clientCode,
        otp: otp,
        mobileConsent: mobileConsent,
        emailConsent: emailConsent,
      },
      idToken: accessToken,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const fetchBank = async (
  accessToken: string | undefined,
  clientCode: string | null
) => {
  try {
    const res = await axiosRequest({
      method: "get",
      path: "bank-details",
      data: {
        clientCode: clientCode,
      },
      idToken: accessToken,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const fetchBranch = async (
  accessToken: string | undefined,
  clientCode: string | null,
  bankName: string | null,
  searchParam: string
) => {
  try {
    const res = await axiosRequest({
      method: "post",
      path: "search-ifsc",
      data: {
        clientCode: clientCode,
        bankName: bankName,
        searchParam: searchParam,
      },
      idToken: accessToken,
    });

    return res.data;
  } catch (err) {
    throw err;
  }
};

export const verifyBank = async (
  accessToken: string | undefined,
  clientCode: string | null,
  bankName: string | null,
  ifscCode: string,
  accountNumber: string
) => {
  try {
    const res = await axiosRequest({
      method: "post",
      path: "verify-bank-account",
      data: {
        clientCode: clientCode,
        bankName: bankName,
        ifscCode: ifscCode,
        accountNumber: accountNumber,
      },
      idToken: accessToken,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const uploadProof = async (
  accessToken: string | undefined,
  formData: FormData
) => {
  try {
    const res = await axiosRequest({
      method: "post",
      path: "store-client-document",
      data: formData,
      idToken: accessToken,
      contentType: "multipart/form-data",
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const uploadDoubleSidedProof = async (
  accessToken: string | undefined,
  formData: FormData | null
) => {
  try {
    const res = await axiosRequest({
      method: "post",
      path: "store-client-document-two-sided",
      data: formData,
      idToken: accessToken,
      contentType: "multipart/form-data",
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const storeSegmentFatca = async (
  accessToken: string | undefined,
  clientCode: string | null
) => {
  try {
    const res = await axiosRequest({
      method: "post",
      path: "store-segment-fatca",
      data: {
        clientCode: clientCode,
        isEquity: "Y",
        isMF: "Y",
        isDerivatives: "Y",
        isDDPI: "Y",
        occupation: "1",
        grossAnnualIncome: "1",
        sourceOfWealth: "1",
        educationQualification: "1",
        tradingExperience: "1",
      },
      idToken: accessToken,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const digiLockerInit = async (
  accessToken: string | undefined,
  clientCode: string | null
) => {
  try {
    const res = await axiosRequest({
      method: "post",
      path: "request-digilocker",
      data: {
        clientCode: clientCode,
      },
      idToken: accessToken,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const storeAddress = async (
  accessToken: string | undefined,
  clientCode: string | null,
  data: Object
) => {
  try {
    const res = await axiosRequest({
      method: "post",
      path: "store-address-details",
      data: {
        clientCode: clientCode,
        ...data,
      },
      idToken: accessToken,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const verifyKra = async (
  accessToken: string | undefined,
  clientCode: string | null,
  pan: string,
  dob: string,
  name: string,
  fatherName: string
) => {
  try {
    const res = await axiosRequest({
      method: "post",
      path: "verify-pan-v2",
      data: {
        clientCode: clientCode,
        pan: pan,
        dob: dob,
        name: name,
        fatherName: fatherName,
      },
      idToken: accessToken,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const verifyPersonalDetails = async (
  accessToken: string | undefined,
  clientCode: string | null,
  data: Object
) => {
  try {
    // throw {
    //   "status": "failure",
    //   "message": "test",
    //   "errors": {
    //     "panError": {
    //       "flag": true,
    //       "message": "string"
    //     }
    //   }
    // };

    const res = await axiosRequest({
      method: "post",
      path: "verify-personal-details",
      data: {
        clientCode: clientCode,
        ...data,
      },
      idToken: accessToken,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const generateOtp = async (
  accessToken: string | undefined,
  clientCode: string | null,
  mobile: string | null,
  email: string | null
) => {
  try {
    const res = await axiosRequest({
      method: "post",
      path: "generate-otp",
      data: {
        clientCode: clientCode,
        mobileNumber: mobile,
        email: email,
      },
      idToken: accessToken,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const digioPreESign = async (
  accessToken: string | undefined,
  clientCode: string | null
) => {
  try {
    const res = await axiosRequest({
      method: "post",
      path: "digio-pre-esign",
      data: {
        clientCode: clientCode,
      },
      idToken: accessToken,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const authenticateEmail = async (
  accessToken: string | undefined,
  clientCode: string | null,
  mobile: string | null,
  email: string | null
) => {
  try {
    const res = await axiosRequest({
      method: "post",
      path: "authenticate-email",
      data: {
        clientCode: clientCode,
        mobileNumber: mobile,
        email: email,
      },
      idToken: accessToken,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const resendEmailVerification = async (
  accessToken: string | undefined,
  clientCode: string | null,
  mobile: string | null,
  email: string | null
) => {
  try {
    const res = await axiosRequest({
      method: "post",
      path: "resend-email-verification",
      data: {
        clientCode: clientCode,
        mobileNumber: mobile,
        email: email,
      },
      idToken: accessToken,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};
export const getElgibility = async (
  accessToken: string | undefined,
  clientCode: string | null
) => {
  try {
    const res = await axiosRequest({
      method: "post",
      path: "check-user-mapping",
      data: {
        clientCode: clientCode,
      },
      idToken: accessToken,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const fetchUserAccountStatus = async (
  accessToken: string | undefined,
  marketfeedDetails: MarketfeedDetails | null
) => {
  try {
    const res = await axiosRequest({
      method: "post",
      path: "fetch-user-account-status",
      data: {
        ...marketfeedDetails,
      },
      idToken: accessToken,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getConsentDetails = async (accessToken: string | undefined) => {
  try {
    const res = await axiosRequest({
      method: "get",
      path: "consent-details",
      idToken: accessToken,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const setClientCodeInCRM = async (data: Object) => {
  try {
    const res = await axiosRequestLMS({
      method: "post",
      path: "lead",
      data: data,
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};
