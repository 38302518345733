import {
  AuthenticateEmail,
  BankDetails,
  ConsentDetailsList,
  DigioPreEsign,
  FetchUserAccountStatus,
  GenerateOtp,
  RequestDigiLocker,
  ResendEmailVerification,
  SearchIFSC,
  StoreAddressDetails,
  StoreClientDocumentTwoSided,
  StoreClientDocuments,
  StoreSegmentFacta,
  TStoreAddressParams,
  TVerifyPersonalDetailsParams,
  VerifyAndRegister,
  VerifyBankAccount,
  VerifyKRA,
  VerifyOtp,
  VerifyPersonalDetails,
  getElgibilityCheck,
} from "./onboardingResponseTypes";
import { INITIAL_STATE } from "./onboardingInitialStateTypes";
import { pageMapping } from "../constant/pageMapping";
import {
  authenticateEmailThunk,
  customTokenLoginThunk,
  digiLockerInitThunk,
  digioPreESignThunk,
  fetchBanksThunk,
  fetchBranchThunk,
  fetchUserAccountStatusThunk,
  generateOtpThunk,
  resendEmailVerificationThunk,
  sendOtp,
  storeAddressThunk,
  storeSegmentFatcaThunk,
  uploadDoubleSidedProofThunk,
  uploadProofThunk,
  verifyBankThunk,
  verifyKraThunk,
  verifyOtpThunk,
  verifyPersonalDetailsThunk,
  getConsentDetailsThunk,
  getElgibilityThunk,
} from "./onboardingThunks";
import { PayloadAction } from "@reduxjs/toolkit";
import { handleIntermediatePage } from "@/util/handleIntermediatePage";
import { stageMapping } from "@/constant/stageMapping";
import { checkBankVerificationLimit } from "@/util/checkBankVerificationLimit";
import { PAGES } from "@/constant/misc";
import moment from "moment";
import removeParam from "@/util/removeParams";

const handleErrorCase = (errors: Object) => {
  let errorMessages: any = {};
  if (errors) {
    Object.entries(errors).map(([key, value]) => {
      if (value.flag) {
        errorMessages[key] = value.message;
      }
    });
  }
  return errorMessages;
};
export const onboardingThunkReducers = {
  [customTokenLoginThunk.pending.type]: (state: INITIAL_STATE) => {
    state.loading = true;
    state.error = null;
  },
  [customTokenLoginThunk.fulfilled.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: any }
  ) => {
    window.localStorage.setItem("userLoggedIn", "true");
    window.location.href = removeParam("token", window.location.href);
    state.loading = false;
    state.marketfeedUser = {
      displayName: payload.displayName,
      uid: payload.uid,
      accessToken: payload.accessToken,
      refreshToken: payload.refreshToken,
    };
  },
  [customTokenLoginThunk.rejected.type]: (
    state: INITIAL_STATE,
    { error }: { error: any }
  ) => {
    state.loading = false;
    state.error = error.message;
  },
  [sendOtp.pending.type]: (state: INITIAL_STATE) => {
    state.loading = true;
    state.error = null;
  },
  [sendOtp.fulfilled.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: VerifyAndRegister }
  ) => {
    state.loading = false;
    state.otpSent = true;
    state.toast = { type: "success", label: "OTP Sent" };
    state.clientCode = payload.clientCode;
  },
  [sendOtp.rejected.type]: (
    state: INITIAL_STATE,
    { error }: { error: any }
  ) => {
    state.loading = false;
    state.toast = { type: "fail", label: "Could not send OTP" };
    state.error = error;
  },
  [verifyOtpThunk.pending.type]: (state: INITIAL_STATE) => {
    state.loading = true;
    state.error = null;
  },
  [verifyOtpThunk.fulfilled.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: VerifyOtp }
  ) => {
    const stage = { ...state }.stage;
    if (payload.status == "success") {
      state.loading = false;
      state.otpVerified = true;
      state.toast = { type: "success", label: "Verified OTP!" };
      if (state.stage == "FORM_TWO_FILLED") {
        state.stage = "ONBOARDING_STARTED";
      }
      state.nextPage = stageMapping?.[stage]?.page || PAGES.VERIFY;
      //state.nextPage = '/personal-details'
      state.error = null;
      window.localStorage.setItem("lastLogin", JSON.stringify(new Date()));
    } else {
      state.loading = false;
      state.toast = { type: "fail", label: "Some error occured" };
    }
  },
  [verifyOtpThunk.rejected.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: any }
  ) => {
    state.loading = false;
    state.error = payload.errors?.otpInvalid?.flag
      ? payload.errors?.otpInvalid?.message
      : "Could not verify";
  },
  [fetchBanksThunk.pending.type]: (state: INITIAL_STATE) => {
    state.loading = true;
    state.error = null;
  },
  [fetchBanksThunk.fulfilled.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: BankDetails }
  ) => {
    if (payload.status == "success") {
      state.loading = false;
      state.error = null;
      state.preferredBankList = payload?.preferredBankList || [];
      state.bankList = payload?.bankList || [];
      state.searchBankList = payload?.bankList || [];
    } else {
      state.loading = false;
      state.toast = { type: "fail", label: "Some error occured" };
    }
  },
  [fetchBanksThunk.rejected.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: any }
  ) => {
    state.loading = false;
    state.toast = { type: "fail", label: "Could not retrieve banks" };
    state.error = payload.errors?.fetchBankListError?.flag
      ? payload.errors?.fetchBankListError?.message
      : "Could not fetch banks";
  },
  [fetchBranchThunk.pending.type]: (state: INITIAL_STATE) => {
    state.branchLoading = true;
    state.loading = true;
    state.error = null;
    state.branchIfscList = [];
  },
  [fetchBranchThunk.fulfilled.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: SearchIFSC }
  ) => {
    state.branchLoading = false;

    if (payload.status == "success") {
      state.loading = false;
      state.error = null;
      state.branchIfscList = payload?.branchList || [];
    } else {
      state.loading = false;
      state.branchLoading = false;
      state.toast = { type: "fail", label: "Some error occured" };
    }
  },
  [fetchBranchThunk.rejected.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: any }
  ) => {
    state.loading = false;
    state.toast = { type: "fail", label: "Could not find your branch" };
    state.error = payload.errors?.ifscAutofillError?.flag
      ? payload.errors?.ifscAutofillError?.message
      : "Could not fetch branches";
  },
  [verifyBankThunk.pending.type]: (state: INITIAL_STATE) => {
    state.loading = true;
    state.pageLoading = "bank-details";
    state.error = null;
  },
  [verifyBankThunk.fulfilled.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: VerifyBankAccount }
  ) => {
    if (payload.status == "success") {
      state.loading = false;
      state.pageLoading = null;
      state.pageSuccess = "bank-details";
      state.error = null;
    } else {
      state.loading = false;
      state.pageError = "bank-details";
      state.pageLoading = null;
      state.toast = { type: "fail", label: "Some error occured" };
    }
  },
  [verifyBankThunk.rejected.type]: (
    state: INITIAL_STATE,
    {
      payload,
    }: {
      payload: {
        error?: { message: string };
        errors?: { [key: string]: { flag: Boolean; message: string } };
      };
    }
  ) => {
    let errorString = payload.error
      ? payload.error?.message
      : payload.errors
      ? Object.values(payload.errors)[0]?.message
      : "";
    if (checkBankVerificationLimit(errorString)) {
      state.bankVerificationLimitExceeded = true;
    }
    state.loading = false;
    state.pageError = "bank-details";
    state.pageLoading = null;
  },
  [uploadProofThunk.pending.type]: (state: INITIAL_STATE) => {
    const currentPage = { ...state }.currentPage;
    const proofCode = { ...state }.proofCode;
    const pending = handleIntermediatePage(currentPage, proofCode);
    state.pageLoading = pending;
    state.loading = true;
    state.error = null;
  },
  [uploadProofThunk.fulfilled.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: StoreClientDocuments }
  ) => {
    const currentPage = { ...state }.currentPage;
    const proofCode = { ...state }.proofCode;
    const success = handleIntermediatePage(currentPage, proofCode);
    state.pageSuccess = success;
    state.pageLoading = null;
    state.loading = false;
    state.isPreviewingDocument = false;
  },
  [uploadProofThunk.rejected.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: any }
  ) => {
    const currentPage = { ...state }.currentPage;
    const proofCode = { ...state }.proofCode;
    const fail = handleIntermediatePage(currentPage, proofCode);
    state.loading = false;
    state.pageError = fail;
    state.proofCode = null;
    state.pageLoading = null;
    state.isPreviewingDocument = false;
    state.error = payload.errors?.bankVerificationError?.flag
      ? payload.errors?.bankVerificationError?.message
      : "Could not fetch branches";
  },
  [storeSegmentFatcaThunk.pending.type]: (state: INITIAL_STATE) => {
    state.loading = true;
    state.error = null;
  },
  [storeSegmentFatcaThunk.fulfilled.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: StoreSegmentFacta }
  ) => {
    if (payload.status == "success") {
      state.loading = false;
      state.toast = {
        type: "success",
        label: "Stored segment and fatca details!",
      };
      state.nextPage = PAGES.FINANCIAL_PROOF;
      state.error = null;
    } else {
      state.loading = false;
      state.toast = {
        type: "fail",
        label: "Could not store segment and fatca details",
      };
    }
  },
  [storeSegmentFatcaThunk.rejected.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: any }
  ) => {
    state.loading = false;
    state.toast = {
      type: "fail",
      label: "Could not store segment and fatca details",
    };
    state.error =
      payload.errors?.segmentDetailsError?.message ||
      payload.errors?.fatcaDetailsError?.message;
  },
  [digiLockerInitThunk.pending.type]: (state: INITIAL_STATE) => {
    state.error = null;
    state.apiLoading = true;
  },
  [digiLockerInitThunk.fulfilled.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: RequestDigiLocker }
  ) => {
    if (payload.status == "success") {
      state.toast = { type: "success", label: "DigiLocker intitated!" };
      state.digiLockerRedirect = payload.digilockerRedirectUri;
      state.error = null;
      state.apiLoading = false;
    } else {
      state.apiLoading = false;
      state.toast = { type: "fail", label: "Could not initiate digiLocker" };
    }
  },
  [digiLockerInitThunk.rejected.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: any }
  ) => {
    state.apiLoading = false;
    state.toast = { type: "fail", label: "Could not initiate digiLocker" };
  },
  [uploadDoubleSidedProofThunk.pending.type]: (state: INITIAL_STATE) => {
    state.loading = true;
    state.isPreviewingDocument = false;
    state.error = null;
  },
  [uploadDoubleSidedProofThunk.fulfilled.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: StoreClientDocumentTwoSided }
  ) => {
    if (payload.status == "success") {
      state.loading = false;
      state.toast = { type: "success", label: "Uploaded address proof" };
      state.nextPage = PAGES.PERSONAL_ADDRESS_ADD_ADDRESS;
    } else {
      state.toast = {
        type: "fail",
        label: "Could not upload proof - Try again",
      };
      state.nextPage = PAGES.PERSONAL_ADDRESS_ADDRESS_PROOF;
      state.loading = false;
    }
  },
  [uploadDoubleSidedProofThunk.rejected.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: any }
  ) => {
    state.toast = { type: "fail", label: "Could not upload proof - Try again" };
    state.nextPage = PAGES.PERSONAL_ADDRESS_ADDRESS_PROOF;
    state.loading = false;
    state.error = payload.errors?.bankVerificationError?.flag
      ? payload.errors?.bankVerificationError?.message
      : "Could not fetch branches";
  },
  [storeAddressThunk.pending.type]: (state: INITIAL_STATE) => {
    state.loading = true;
    state.pageLoading = "address-proof";
    state.error = null;
  },
  [storeAddressThunk.fulfilled.type]: (
    state: INITIAL_STATE,
    action: PayloadAction<
      StoreAddressDetails,
      string,
      { arg: TStoreAddressParams }
    >
  ) => {
    if (action.payload.status == "success") {
      state.loading = false;
      state.pageLoading = null;
      state.pageSuccess = "address-proof";
    } else {
      state.loading = false;
      state.pageError = "address-proof";
      state.pageLoading = null;
    }
  },
  [storeAddressThunk.rejected.type]: (
    state: INITIAL_STATE,
    action: PayloadAction<
      StoreAddressDetails,
      string,
      { arg: TStoreAddressParams }
    >
  ) => {
    state.loading = false;
    state.pageError = "address-proof";
    state.pageLoading = null;
  },
  [verifyKraThunk.pending.type]: (state: INITIAL_STATE) => {
    state.loading = true;
  },
  [verifyKraThunk.fulfilled.type]: (
    state: INITIAL_STATE,
    action: PayloadAction<
      VerifyKRA,
      string,
      { arg: { pan: string; dob: string } }
    >
  ) => {
    state.loading = false;
    const stage = { ...state }.stage;
    const currentPage = { ...state }.currentPage;
    const isKraVerified = { ...state }.isKraVerified;

    if (action.payload.status == "success") {
      if (action.payload.isKraVerified) {
        state.toast = {
          type: "success",
          label: "Personal details submitted successfully",
        };
        state.nextPage = PAGES.BANK_DETAILS;
      } else {
        state.isKraVerified = isKraVerified + 1;
        state.toast = {
          type: "fail",
          label:
            "Could not verify details please check\nthe details and try again",
        };
      }
    } else {
      state.loading = false;
      state.isKraVerified = isKraVerified + 1;
      state.toast = {
        type: "fail",
        label:
          "Could not verify details please check\nthe details and try again",
      };
    }
  },
  [verifyKraThunk.rejected.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: any }
  ) => {
    const isKraVerified = { ...state }.isKraVerified;
    let error = handleErrorCase(payload.errors);
    state.isKraVerified = isKraVerified + 1;

    state.apiError = error;
    state.loading = false;
    state.toast = {
      type: "fail",
      label: "Could not verify details please check\nthe details and try again",
    };
  },
  [verifyPersonalDetailsThunk.pending.type]: (
    state: INITIAL_STATE,
    action: PayloadAction<{}, string, { arg: TVerifyPersonalDetailsParams }>
  ) => {
    state.loading = true;
    state.pageLoading = "personal-details";
    state.error = null;
  },
  [verifyPersonalDetailsThunk.fulfilled.type]: (
    state: INITIAL_STATE,
    action: PayloadAction<
      VerifyPersonalDetails,
      string,
      { arg: TVerifyPersonalDetailsParams }
    >
  ) => {
    if (action.payload.status == "success") {
      state.loading = false;
      state.pageLoading = null;
      state.pageSuccess = "personal-details";
    } else {
      state.loading = false;
      state.pageLoading = null;
      state.pageError = "personal-details";
    }
  },
  [verifyPersonalDetailsThunk.rejected.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: any }
  ) => {
    state.loading = false;
    state.pageLoading = null;
    state.pageError = "personal-details";
    let error = handleErrorCase(payload.errors);
    state.apiError = error;
  },
  [generateOtpThunk.pending.type]: (state: INITIAL_STATE) => {
    state.refreshLoading = true;
  },
  [generateOtpThunk.fulfilled.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: GenerateOtp }
  ) => {
    if (payload.status == "success") {
      state.refreshLoading = false;
      state.toast = { type: "success", label: "New OTP Sent!" };
    } else {
      state.refreshLoading = false;
      state.toast = { type: "fail", label: "Could not send new OTP" };
      state.nextPage = PAGES.VERIFY;
    }
  },
  [generateOtpThunk.rejected.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: any }
  ) => {
    state.refreshLoading = false;
    state.toast = { type: "fail", label: "Could not send new OTP" };
    state.nextPage = PAGES.VERIFY;
  },

  [digioPreESignThunk.pending.type]: (state: INITIAL_STATE) => {
    state.loading = true;
    state.apiLoading = true;
  },
  [digioPreESignThunk.fulfilled.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: DigioPreEsign }
  ) => {
    if (payload.status == "success") {
      state.loading = false;
      state.toast = { type: "success", label: "Digio ESign Initiated!" };
      state.digioRedirect = {
        redirectUrl: payload.iiflEsignRedirectUri,
        requestId: payload.iiflEsignRequestId,
      };
      state.apiLoading = false;
    } else {
      state.showModal = true;
      state.modalType = "iiflUnavailable";
      state.unavailableTryAgain = "digioPreESignThunk";
      state.loading = false;
      state.apiLoading = false;
      state.loading = false;
      state.toast = { type: "fail", label: "Could not initiate digio esign" };
    }
  },
  [digioPreESignThunk.rejected.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: any }
  ) => {
    state.showModal = true;
    state.modalType = "iiflUnavailable";
    state.unavailableTryAgain = "digioPreESignThunk";
    state.loading = false;
    state.apiLoading = false;
    state.loading = false;
    state.toast = { type: "fail", label: "Could not initiate digio esign" };
  },
  [authenticateEmailThunk.pending.type]: (
    state: INITIAL_STATE,
    action: PayloadAction<any, string, { arg: { toast: Boolean } }>
  ) => {
    if (!action.meta.arg.toast) {
      state.apiLoading = true;
    }
    state.refreshLoading = true;
  },
  [authenticateEmailThunk.fulfilled.type]: (
    state: INITIAL_STATE,
    action: PayloadAction<
      AuthenticateEmail,
      string,
      { arg: { toast: Boolean } }
    >
  ) => {
    if (!action.meta.arg.toast) {
      state.apiLoading = false;
    }
    if (action.payload.status == "success") {
      state.refreshLoading = false;
      if (action.payload.isEmailAuthenticated) {
        if (action.meta.arg.toast) {
          state.toast = { type: "success", label: "Email address verified!" };
        }
        state.modalType = null;
        state.showModal = false;
        state.emailVerified = true;
      } else {
        if (action.meta.arg.toast) {
          state.toast = {
            type: "fail",
            label: "Email address not verified yet",
          };
        }
        state.emailVerified = false;
      }
    } else {
      state.refreshLoading = false;
      if (action.meta.arg.toast) {
        state.toast = { type: "fail", label: "Email address not verified yet" };
      }
      state.emailVerified = false;
    }
  },
  [authenticateEmailThunk.rejected.type]: (
    state: INITIAL_STATE,
    action: PayloadAction<any, string, { arg: { toast: Boolean } }>
  ) => {
    if (!action.meta.arg.toast) {
      state.apiLoading = false;
    }
    state.refreshLoading = false;
    state.toast = { type: "fail", label: "Email address not verified yet" };
    state.emailVerified = false;
  },
  [resendEmailVerificationThunk.pending.type]: (state: INITIAL_STATE) => {
    state.loading = true;
  },
  [resendEmailVerificationThunk.fulfilled.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: ResendEmailVerification }
  ) => {
    if (payload.status == "success") {
      state.loading = false;
      state.toast = { type: "success", label: "Verification link sent!" };
    } else {
      state.loading = false;
      state.toast = {
        type: "fail",
        label: "Could not send new verification link",
      };
    }
  },
  [resendEmailVerificationThunk.rejected.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: any }
  ) => {
    state.loading = false;
    state.toast = {
      type: "fail",
      label: "Could not send new verification link",
    };
  },
  [fetchUserAccountStatusThunk.pending.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: any }
  ) => {
    state.apiLoading = true;
  },
  [fetchUserAccountStatusThunk.fulfilled.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: FetchUserAccountStatus }
  ) => {
    const { otpVerified, pageError, pageSuccess, currentPage } = { ...state };

    const handleFailure = () => {
      state.pageError = null;
      state.pageLoading = null;
      state.pageSuccess = null;
      state.showModal = false;
      state.modalType = null;
      state.nextPage = PAGES.VERIFY;
    };

    const checkSessionTimeout = () => {
      let userDetails = window.localStorage.getItem("userCredentials");
      let clientCode = window.localStorage.getItem("clientCode");
      let lastLoginString = window.localStorage.getItem("lastLogin");
      if (lastLoginString && userDetails && clientCode) {
        let lastLogin = JSON.parse(lastLoginString);
        let prev = moment(lastLogin);
        let now = moment(new Date());
        var result = moment(now).diff(moment(prev), "seconds");
        if (result > 86400) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    };

    if (
      ![
        PAGES.LOADING,
        PAGES.APP_LOADING,
        PAGES.LINK_ACCOUNT_CHECK_ELIGIBILITY,
        PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS,
        PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS_ACTIVATE_DDPI,
        PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS_ACTIVATE_FNO,
        PAGES.LINK_ACCOUNT_AUTHORISE_TRADES,
        PAGES.LINK_ACCOUNT_HOW_TO_RESOLVE,
      ].includes(currentPage)
    ) {
      if (payload.status == "success") {
        state.stage = payload.stage;
        if (checkSessionTimeout()) {
          handleFailure();
        } else {
          if (
            ![
              PAGES.ESIGN_STATUS,
              PAGES.PERSONAL_ADDRESS_DIGILOCKER,
              PAGES.LOADING,
              PAGES.APP_LOADING,
              PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS,
              PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS_ACTIVATE_DDPI,
              PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS_ACTIVATE_FNO,
              PAGES.LINK_ACCOUNT_AUTHORISE_TRADES,
              PAGES.LINK_ACCOUNT_CHECK_ELIGIBILITY,
              PAGES.LINK_ACCOUNT_HOW_TO_RESOLVE,
            ].includes(currentPage)
          ) {
            if (
              process.env.NEXT_PUBLIC_TESTING_ENVIRONMENT &&
              !JSON.parse(process.env.NEXT_PUBLIC_TESTING_ENVIRONMENT)
            )
              state.nextPage = stageMapping[payload.stage]?.page;
          }
        }
      } else {
        handleFailure();
      }
    }
    state.apiLoading = false;
    state.initialLoading = false;

    // if(!['SIGNATURE_UPLOADED'].includes(payload.stage)){
    // state.initialLoading = false;
    // }
  },

  [fetchUserAccountStatusThunk.rejected.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: FetchUserAccountStatus }
  ) => {
    state.apiLoading = false;
    const { otpVerified, pageError, pageSuccess, currentPage } = { ...state };
    if (
      ![
        PAGES.LOADING,
        PAGES.APP_LOADING,
        PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS,
        PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS_ACTIVATE_DDPI,
        PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS_ACTIVATE_FNO,
        PAGES.LINK_ACCOUNT_AUTHORISE_TRADES,
        PAGES.LINK_ACCOUNT_CHECK_ELIGIBILITY,
        PAGES.LINK_ACCOUNT_HOW_TO_RESOLVE,
      ].includes(currentPage)
    ) {
      if (
        process.env.NEXT_PUBLIC_TESTING_ENVIRONMENT &&
        !JSON.parse(process.env.NEXT_PUBLIC_TESTING_ENVIRONMENT)
      ) {
        state.pageError = null;
        state.pageLoading = null;
        state.pageSuccess = null;
        state.nextPage = PAGES.VERIFY;
      }
    } else if (
      [
        PAGES.LOADING,
        PAGES.APP_LOADING,
        PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS,
        PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS_ACTIVATE_DDPI,
        PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS_ACTIVATE_FNO,
        PAGES.LINK_ACCOUNT_AUTHORISE_TRADES,
        PAGES.LINK_ACCOUNT_CHECK_ELIGIBILITY,
        PAGES.LINK_ACCOUNT_HOW_TO_RESOLVE,
      ].includes(currentPage)
    ) {
      state.initialLoading = false;
    }
  },
  [getConsentDetailsThunk.pending.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: any }
  ) => {
    state.loading = true;
    state.apiLoading = true;
  },
  [getConsentDetailsThunk.fulfilled.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: ConsentDetailsList }
  ) => {
    if (payload.status == "success") {
      state.loading = false;
      state.apiLoading = false;
      state.consentData = payload.consentDetailsList;
    } else {
      state.loading = false;
    }
  },
  [getConsentDetailsThunk.rejected.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: ConsentDetailsList }
  ) => {
    state.loading = false;
    state.apiLoading = false;
    state.toast = { type: "fail", label: "Could not load consent details" };
  },

  [getElgibilityThunk.pending.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: any }
  ) => {
    state.loading = true;
  },
  [getElgibilityThunk.fulfilled.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: getElgibilityCheck }
  ) => {
    if (payload.status === "success") {
      state.loading = false;
      state.eligibility = payload.userMappingStatus;
      state.fnoActivated = payload.fnoActivated;
      state.ddpiActivated = payload.ddpiActivated;
      state.showEligibility = true;
      state.clientName = payload.clientName;
    } else {
      state.loading = false;
    }
  },
  [getElgibilityThunk.rejected.type]: (
    state: INITIAL_STATE,
    { payload }: { payload: getElgibilityCheck }
  ) => {
    state.loading = false;
    state.unavailableTryAgain = "elgibilityCheck";
    state.showModal = true;
    state.modalType = "iiflUnavailable";
  },
};
